import { useState } from "react";

import Header from "../../components/headerBlue/header.component";
import Sidebar from "../../components/sidebar/sidebar.component";

const PageWrapper = ({ children, sidebarData }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <>
      <Header
        onClick={() => setShowSidebar(!showSidebar)}
        showSidebar={showSidebar}
      />
      <Sidebar showSidebar={showSidebar} sidebarData={sidebarData} />
      <div className="content-container">{children}</div>
    </>
  );
};

export default PageWrapper;
