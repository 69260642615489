import { Link } from "react-router-dom";

import { SectionWrapper } from "../../components/section-wrapper/sectionWrapper.styles";
import CommissionsTable from "../../components/commissions/commisions.component";

const ClientsCard = ({ commissions }) => {
  const sortCommission = (commissions) => {
    return commissions.sort((a, b) => {
      return b.amount - a.amount;
    });
  };

  return (
    <SectionWrapper title={"Clients"} fullWidth={true} card={false}>
      <div className="section__container">
        <div className="tables__container">
          {commissions.length > 0 ? (
            <CommissionsTable
              commissions={sortCommission(commissions).slice(0, 3)}
              title={"Top Earning Breakdown"}
            />
          ) : null}
        </div>
      </div>
      <Link to={"/user/clients"}>View Clients</Link>
    </SectionWrapper>
  );
};

export default ClientsCard;
