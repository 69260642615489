import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectCurrentUser } from "../../redux/user/user.selectors";
import { selectCurrentDistro } from "../../redux/distro/distro.selector";

import { Spinner } from "../../components/spinner/spinner.component";
import { SectionWrapper } from "../../components/section-wrapper/sectionWrapper.styles";
import CommissionsTable from "../../components/commissions/commisions.component";
import SummaryTable from "../../components/summary/summary.component";

import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";

import { CSVLink } from "react-csv";

import DownLoadCSV from "../../assets/csv.png";
import DownLoadPDF from "../../assets/pdf.png";

import { formatDate, generatePDF } from "./utils";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "./financials.styles.scss";

const FinancialsSection = ({ currentUser, currentDistro }) => {
  const [rangeOption, setOption] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [financialInfo, setFinancialInfo] = useState({
    commission: [],
    summary: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_URL_DEV
          : process.env.REACT_APP_API_URL_LOCAL;

  const portal = () => {
    const { innerWidth: width } = window;
    if (width <= 500) {
      return true;
    } else {
      return false;
    }
  };

  const fetchData = async (startingDate, endingDate) => {
    try {
      setIsLoading(true);
      const accessToken = currentUser.token;
      const response = await fetch(
        `${apiUrl}/financials/${currentDistro}/${formatDate(
          startingDate
        )}/${formatDate(endingDate)}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setIsLoading(false);
        if (isLoading === false) {
          setFinancialInfo(data);
        }
      } else {
        setIsLoading(false);
        throw new Error(response.statusText);
      }
    } catch (err) {
      alert(
        "An error has occured while retrieving data. Please refresh the page and try again."
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (startDate && endDate) {
      fetchData(startDate, endDate);
    } else {
      alert("Please select a date");
    }
  };

  useEffect(() => {
    const defaultEndDate = new Date();
    let defaultStarDate = new Date();
    defaultStarDate.setDate(defaultStarDate.getDate() - 30);
    setStartDate(defaultStarDate);
    setEndDate(defaultEndDate);
    if (currentDistro !== null) {
      fetchData(defaultStarDate, defaultEndDate);
    }
    return (isLoading) => (isLoading = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDistro]);

  useEffect(() => {
    const date = new Date();
    if (rangeOption === "yearToDate") {
      setStartDate(new Date(date.getFullYear(), 0, 1));
      setEndDate(new Date(date.getFullYear(), 11, 31));
    }
    return (isLoading) => (isLoading = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeOption]);

  const tableData = [...financialInfo.summary, ...financialInfo.commission];

  return (
    <SectionWrapper title={"Financials"}>
      <div className="section__container">
        <form className="filter__form__container" onSubmit={handleSubmit}>
          <select
            required
            name="group_by"
            className="filter__input__distribution"
            onChange={(e) => setOption(e.target.value)}
          >
            <option className="filter__input_value" value="">
              Select Range
            </option>
            <option value="dateRange">Date Range</option>
            <option value="yearToDate">Year to date</option>
          </select>
          {rangeOption === "dateRange" ? (
            <>
              <DatePicker
                className="filter__input"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                placeholderText="Date from"
                required
                withPortal={portal()}
                disabledKeyboardNavigation={portal()}
              />
              <DatePicker
                className="filter__input"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={new Date()}
                placeholderText="Date to"
                required
                withPortal={portal()}
                disabledKeyboardNavigation={portal()}
              />
            </>
          ) : null}

          <Button
            className="py-2 px-4"
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Filter
          </Button>
        </form>
        <div className="tables__container">
          {financialInfo.summary.length > 0 ? (
            <SummaryTable summary={financialInfo.summary} />
          ) : null}
          {financialInfo.commission.length > 0 ? (
            <CommissionsTable
              commissions={financialInfo.commission}
              title={"Commission Breakdown"}
            />
          ) : null}

          {isLoading ? (
            <div className="financial__spinner">
              <Spinner className="spin" style={{ display: "none" }} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="download__container">
        <h2 className="header__title">Download detailed report:</h2>
        <div>
          <CSVLink
            data={tableData}
            headers={[
              { label: "Type", key: "type" },
              { label: "Company", key: "name" },
              { label: "Amount", key: "amount" },
            ]}
            filename={`FinancialsReport.csv`}
            data-toggle="tooltip"
            data-placement="top"
            title="Download CSV"
            trigger="hover"
          >
            <img src={DownLoadCSV} alt="CSV" className="download-icon" />
          </CSVLink>
          <span
            onClick={() =>
              generatePDF(
                financialInfo,
                formatDate(startDate),
                formatDate(endDate)
              )
            }
          >
            <img src={DownLoadPDF} alt="PDF" className="download-icon" />
          </span>
        </div>
      </div>
    </SectionWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentDistro: selectCurrentDistro,
});

export default connect(mapStateToProps)(FinancialsSection);
