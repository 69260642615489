import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";

import { setCurrentUser } from "../../redux/user/user.actions";
import { setCurrentDistro } from "../../redux/distro/distro.actions";
import { selectCurrentUser } from "../../redux/user/user.selectors";

import { Link, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { ownerSidebarData, advisorSidebarData } from "./sidebarData";

import "./sidebar.scss";

const Sidebar = ({
  currentUser,
  setCurrentUser,
  setCurrentDistro,
  showSidebar,
}) => {
  const location = useLocation();

  const sidebarData =
    currentUser.role === "owner" ? ownerSidebarData : advisorSidebarData;

  return (
    <div className={`sidebar-container ${showSidebar ? "show-sidebar" : null}`}>
      {/*<div>*/}
      {/*  <p className="sidebar-message">*/}
      {/*    Welcome to NHC's Advisor Portal! We'll be adding more resources for your*/}
      {/*    business -- including marketing courses, online enrollment and more. So*/}
      {/*    stay tuned!*/}
      {/*  </p>*/}
      {/*</div>*/}
      {/*<hr></hr>*/}
      {sidebarData.map((item, idx) => {
        return (
          <div key={idx} className="sidebar-section-container">
            <h2 className="sidebar-section-title">{item.section}</h2>
            {item.links.map((link) => {
              return !link.externalLink ? (
                <Link
                  key={link.title}
                  className={`sidebar__link ${
                    location.pathname === link.path
                      ? "sidebar__link-active"
                      : null
                  }`}
                  to={link.path}
                >
                  {link.title}
                </Link>
              ) : (
                <Link
                  key={link.title}
                  className="sidebar__link"
                  to={{
                    pathname: `${link.path}`,
                  }}
                  target="_blank"
                >
                  {link.title}
                </Link>
              );
            })}
            <hr></hr>
          </div>
        );
      })}

      <Button
        className="px-4 m-2"
        variant="primary"
        type="submit"
        onClick={() => {
          setCurrentDistro(null);
          setCurrentUser();
        }}
      >
        Logout
      </Button>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: () => dispatch(setCurrentUser()),
  setCurrentDistro: (data) => dispatch(setCurrentDistro(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
