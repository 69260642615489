import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setCurrentUser } from "../../redux/user/user.actions";
import { selectCurrentUser } from "../../redux/user/user.selectors";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import logo from "../../assets/nhclogo.png";

import "./sign-in-page.styles.scss";

const SignInPage = ({ currentUser, setCurrentUser }) => {
  const initialState = {
    username: "",
    password: "",
    error: false,
    message: null,
  };

  const [user, setUser] = useState({ ...initialState });

  const apiUrl = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_URL_DEV
          : process.env.REACT_APP_API_URL_LOCAL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: user.username,
          password: user.password,
        }),
      };
      const response = await fetch(
          `${apiUrl}/users/authenticate`,
          requestOptions
      );
      const data = await response.json();
      if (!data.message) {
        setUser({ ...user, error: false });
        setCurrentUser(data);
      } else {
        setUser({ ...user, message: data.message, error: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    e.target.name === "username"
      ? setUser({ ...user, username: e.target.value })
      : setUser({ ...user, password: e.target.value });
  };

  let history = useHistory();
  useEffect(() => {
    if (currentUser != null) {
      if (currentUser.role === "owner") {
        history.push("/user/dashboard");
      } else {
        history.push("/user/profile");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div className="page__container">
      <div className="form__container">
        <img className="logo" src={logo} alt="Logo" />

        <form className="signin__form" onSubmit={handleSubmit}>
          <input
            className="signin__input"
            name="username"
            type="email"
            onChange={handleChange}
            value={user.username}
            placeholder="Enter your username"
            required
          />
          <input
            className="signin__input mb-1 "
            name="password"
            type="password"
            onChange={handleChange}
            value={user.password}
            placeholder="Enter your password"
            required
          />
          {user.error === false && user.message === null ? null : user.error ===
              true && user.message !== null ? (
            <Alert key={"alert"} variant={"danger"}>
              {user.message}
            </Alert>
          ) : (
            <Alert key={"alert"} variant={"success"}>
              {user.message}
            </Alert>
          )}

          <Link className="link" to="/resetpassword">
            Forgot password?
          </Link>

          <Button
            onSubmit={handleSubmit}
            className="signin__button"
            variant="primary"
            type="submit"
          >
            Sign In
          </Button>
        </form>
        <span className="link__container">
          {`First time user? `}
          <Link className="link" to="/resetpassword">
            Click here
          </Link>
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (data) => dispatch(setCurrentUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
