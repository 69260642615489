import "./dashboard-wrapper.scss";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DashboardWrapper = ({ children }) => {
  const today = new Date();
  return (
    <div className="dashboard__container">
      <div>
        <h2 className="dashboard__title">
          Dashboard{" "}
          <span>{`for ${
            months[today.getMonth()]
          }  ${today.getUTCFullYear()}`}</span>
        </h2>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default DashboardWrapper;
