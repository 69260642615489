export const IndemnityContract = () => {
    return <div className={"px-5 mt-3"}>
        <div>
            <b>WHEREAS</b> NHC and the Subscriber wish to enter into an indemnity
            contract whereby NHC, for consideration, agrees to indemnify certain persons
            in respect of certain hospital expenses, medical expenses, medical plans or
            any combination of such expenses or plans;
        </div>
        <div className={"my-3"}>
            <b>NOW THEREFORE THIS AGREEMENT WITNESSETH</b> that in consideration of the
            mutual premises and covenants herein contained, the receipt and sufficiency
            of which is hereby acknowledged, it is agreed that:
        </div>
        <div className={"mt-3"}>
            <b>Definitions</b>
            <div className={"mt-3"}>1. The following definitions shall be used
                throughout this agreement:
            </div>
            <div className={"mt-3"}>
                <ol type="a">
                    <li className={"mt-2"}>"Administrative Fee" means the fee to be paid
                        by the Subscriber
                        to NHC concurrently with the Eligible Medical Expenditure, as
                        outlined in Schedule “B”, plus any applicable taxes payable
                        thereon, including Goods and Services Tax and any applicable
                        Provincial taxes;
                    </li>
                    <li className={"mt-2"}>"Claim Adjudication" means the process
                        whereby NHC adjudicates a
                        claim of a Qualified Person to determine, among other things,
                        whether such claim is a type which is covered by the PHSP and
                        whether such claim has been paid by the Qualified Person;
                    </li>
                    <li className={"mt-2"}>
                        “Eligible Medical Services” means only those hospital expenses,
                        medical expenses, medical plans or any combination of such
                        expenses or plans which, pursuant to the Income Tax Act
                        (Canada), including section 248(1) and section 118.2(2) thereof
                        and regulations thereunder, may be included in the PHSP;
                    </li>
                    <li className={"mt-2"}>
                        “Eligible Medical Expenditure” means expenditures in respect of
                        Eligible Medical Services provided to a Qualified Person, up to
                        maximum amounts as set out in Schedule “A” hereto;
                    </li>
                    <li className={"mt-2"}>
                        “Fee Guide” means the Schedule of Administrative Fees which is
                        attached as Schedule “B” hereto, forming part of this Agreement;
                    </li>
                    <li className={"mt-2"}>
                        “PHSP” means the private health services plan (known as the
                        Health Spending Account)to be established and administered
                        pursuant to this indemnity contract by NHC on behalfof the
                        Subscriber wherein, upon a Qualified Person making an Eligible
                        MedicalExpenditure, the Subscriber shall remit the Premium to
                        NHC, following which, upon a satisfactory Claim Adjudication,
                        NHC shall indemnify the Qualified Person for the cost of the
                        Eligible Medical Expenditure;
                    </li>
                    <li className={"mt-2"}>
                        “Premium” means the sum of the Eligible Medical Expenditure, the
                        Administrative Fee and any applicable taxes payable thereon,
                        including Goods and Services Tax and any applicable Provincial
                        taxes;
                    </li>
                    <li className={"mt-2"}>
                        “Qualified Person” means such employees of the Subscriber and
                        other persons as are set out in Schedule “A” hereto who may,
                        depending on the plan offered by the Subscriber, include the
                        spouse or common law partner of the employee (including samesex
                        common-law partners) or any member of that employee’s household
                        who is a dependent of that employee, as defined for purposes of
                        the Canada Income Tax Act, so long as there is a contract of
                        employment in good standing;
                    </li>
                </ol>
            </div>
        </div>
        <div className={"mt-3"}>
            <b>Covenants of the Subscriber</b>
            <div className={"mt-3"}>2. Upon receipt of a claim from a Qualified Person
                (employee) for reimbursement of a medical expenditure, the Subscriber
                (company / policy holder) shall pay the Premium and applicable taxes to
                NHC, for NHC to perform the Claim Review and Settlement process.
            </div>
            <div className={"mt-3"}>3. The Subscriber shall keep NHC immediately
                informed of any changes to the information in Schedule “A” hereto, which
                shall be amended accordingly. These changes are to be kept current on
                the secure HSA web site.
            </div>
        </div>
        <div className={"mt-3"}>
            <b>Covenants of NHC</b>
            <div className={"mt-3"}>
                4. In the event that the Premium is paid to NHC by way of non-certified
                funds, NHC will not be obliged to pay any funds out of its trust account
                until such funds have actually cleared and been deposited
                unconditionally to NHC’s trust account.
            </div>
            <div className={"mt-3"}>
                5.Subject to paragraph 4 herein, upon receipt of the Premium NHC shall:
            </div>
            <div className={"mt-3"}>
                <ol type="a">
                    <li className={"mt-2"}>commence the Claim Adjudication; and
                    </li>
                    <li className={"mt-2"}>maintain the Premium in its trust account on
                        behalf of the Subscriber until such time as NHC either: i. pays
                        the amount of the Eligible Medical Expenditure to the Qualified
                        Person and subsequently transfers the Administrative Fee to
                        itself, all in accordance with paragraph 6 herein; or ii.
                        returns the Premium to the Subscriber pursuant to paragraph 7
                        herein.
                    </li>
                </ol>
            </div>
            <div className={"mt-3"}>
                6. In the event that, through the Claim Adjudication process, NHC
                determines that the claim is one which is covered by the Health Spending
                Account, it shall:
            </div>
            <div className={"mt-3"}>
                <ol type="a">
                    <li className={"mt-2"}>indemnify that Qualified Person in respect of
                        such claim by issuing a cheque in the amount of such Eligible
                        Medical Expenditure to such Qualified Person;
                    </li>
                    <li className={"mt-2"}>issue a Premium statement to the Subscriber;
                        and
                    </li>
                    <li className={"mt-2"}>transfer the Administrative Fee to its own
                        account.
                    </li>
                </ol>
            </div>
            <div className={"mt-3"}>
                7. In the event that, through the Claim Adjudication process, NHC
                determines for any reason that the claim is not one which is covered by
                the PHSP, it shall forthwith return the Premium to the Subscriber, or
                Hold the balance of Premium in the Subscriber’s account for future use,
                and inform the Qualified Person with an explanation of why the claim is
                not covered.
            </div>
            <div className={"mt-3"}>
                8. NHC shall use commercially reasonable efforts to ensure that the
                Claim Adjudication process and the indemnification of a Qualified Person
                (if required) are completed in a timely manner.
            </div>
            <div className={"mt-3"}>
                9. NHC shall not become entitled to the Administrative Fee unless and
                until the cheque or payment in the amount of the Eligible Medical
                Expenditure (if required) has been issued to the Qualified Person.
            </div>
            <div className={"mt-3"}>
                10. NHC reserves the right to change the Administrative Fee on 30 days’
                written notice to the Subscriber.
            </div>
        </div>
        <div className={"mt-3"}>
            <b>Conditions Precedent</b>
            <div className={"mt-3"}>
                11. Conditions precedent to the performance of this indemnity agreement
                are that:
            </div>
            <div className={"mt-3"}>
                <ol type="a">
                    <li className={"mt-2"}>there shall be a contract of employment in
                        good standing between the Subscriber and the Qualified Person or
                        the person through whom such Qualified Person makes a claim
                        pursuant to the PHSP; and
                    </li>
                    <li className={"mt-2"}>the Subscriber shall have an undertaking or a
                        contractual obligation to the Qualified Persons, and each of
                        them, to indemnify such Qualified Persons in respect of Eligible
                        Medical Expenses;
                    </li>
                </ol>
            </div>
            <div className={"mt-3"}>
                and that by submitting a Premium and documentation to NHC pursuant to
                paragraph 2 herein, the Subscriber represents to NHC that such
                conditions precedent exist.
            </div>
        </div>
        <div className={"mt-3"}>
            <b>Enforcement by Qualified Persons</b>
            <div className={"mt-3"}>
                12. NHC agrees that Qualified Persons are entitled to enforce NHC’s
                obligations to indemnify them pursuant to this agreement notwithstanding
                that such Qualified Persons are not named, nor have they signed, as
                parties to this agreement and that in the event of such enforcement, NHC
                shall not raise the issue of privity of contract.
            </div>
        </div>
        <div className={"mt-3"}>
            <b>General</b>
            <div className={"mt-3"}>
                13. This Agreement shall be governed by and construed in accordance with the laws of the Province of Alberta
                and the laws of Canada applicable therein.
            </div>
            <div className={"mt-3"}>
                14. The Parties agree that the within agreement is an indemnity contract in respect of hospital expenses,
                medical expenses or any combination of such expenses pursuant to the Canada Income Tax Act, including
                section 248(1) therein and that they will construe and interpret this agreement accordingly.
            </div>
            <div className={"mt-3"}>
                15. Headings used in this Agreement are used for convenience only and shall not form the basis for any
                interpretation of the terms of intent of this Agreement.
            </div>
            <div className={"mt-3"}>
                16. If one or more of the provisions of this agreement or any part of them is, or adjudged to be, invalid,
                illegal or unenforceable in any respect, the validity, legality and enforceability of the remaining
                provisions hereof shall not in any way be affected or impaired thereby, and such invalid, illegal or
                unenforceable provision or part shall be deemed to be severable.
            </div>
            <div className={"mt-3"}>
                17. The Subscriber acknowledges that it has had the opportunity to obtain its own legal and tax advice with
                respect to this agreement.
            </div>
            <div className={"mt-3"}>
                18. Schedules “A” and “B” referred to herein and attached hereto are incorporated by reference to and form
                part of this agreement.
            </div>
            <div className={"mt-3"}>
                19. This agreement may be executed and delivered in separate counterparts and by facsimile, each of which
                when so executed and delivered shall constitute the one in the same instrument.
            </div>
        </div>
    </div>
}