import "./distribution-summary-total.style.scss";

const DistributionSummaryTotal = ({
  spendingAccountsTotal,
  total,
}) => {
  return (
    <div className="responsive__table__container">
      <h2 className="header__title">Summary</h2>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Type</th>
            <th scope="col" className="text-right">
              Amount
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="col">Spending Accounts</th>
            <td
              key={spendingAccountsTotal}
              className="text-right"
            >{`$${spendingAccountsTotal}`}</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <th scope="col" key={total} className="text-right">
              Total: ${total}
            </th>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DistributionSummaryTotal;
