import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { SectionWrapper } from "../../section-wrapper/sectionWrapper.styles";
import InfoItem from "../info-item/info-item.component";
import Button from "react-bootstrap/Button";

import { selectCurrentUser } from "../../../redux/user/user.selectors";
import { setCurrentDistro } from "../../../redux/distro/distro.actions";

import "./account-info.styles.scss";

const AccountInfoOwner = ({ info, currentUser, setCurrentDistro }) => (
  <SectionWrapper title={"Account information"}>
    <div className="owner__info__container">
      <InfoItem label="Name: " value={`${info.name}`}></InfoItem>
      <InfoItem label="Description: " value={info.description}></InfoItem>
      <InfoItem label="Account Id: " value={info.accountId}></InfoItem>
      <InfoItem label="Distro Id: " value={info.distroId}></InfoItem>
      <InfoItem label="Contact Email: " value={info.email}></InfoItem>
      <InfoItem label="Address 1: " value={info.mailingStreet1}></InfoItem>
      <InfoItem label="Address 2: " value={info.mailingStreet2}></InfoItem>
      <InfoItem label="City: " value={info.mailingCity}></InfoItem>
      <InfoItem label="Province: " value={info.mailingProv}></InfoItem>
      <InfoItem label="Postal Code: " value={info.mailingPostalCode}></InfoItem>
      <InfoItem label="Eft: " value={info.eft}></InfoItem>
      <InfoItem label="Phone: " value={info.phone}></InfoItem>
    </div>
    {currentUser.distros.length > 1 ? (
      <Button
        className="px-4 distroBtn"
        variant="primary"
        onClick={() => setCurrentDistro(null)}
      >
        Change Account
      </Button>
    ) : null}
  </SectionWrapper>
);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentDistro: (data) => dispatch(setCurrentDistro(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfoOwner);
