import Button from "react-bootstrap/Button";

import "./distros-list.scss";

const DistroList = ({ distros, handleClick }) => (
  <div className="distro__container">
    <h4 className="distro__title"> Select account</h4>
    {distros.map((distro) => (
      <div key={distro.id} className="distro__item">
        <h6 className="distro__name">{distro.name}</h6>
        <Button
          className="py-2 px-4 distro__button"
          data-id={distro.id}
          variant="primary"
          type="submit"
          onClick={handleClick}
        >
          View
        </Button>
      </div>
    ))}
  </div>
);

export default DistroList;
