import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../assets/nhclogo.png";

export const formatDate = (customDate) => {
  const year = customDate.getFullYear();
  const month = ("0" + (customDate.getMonth() + 1)).slice(-2);
  const day = ("0" + customDate.getDate()).slice(-2);
  const formatedDate = `${year}-${month}-${day}`;
  return formatedDate;
};

export const getTodayDate = (endDate) => {
  if (endDate.slice(5, 7) === "12" && endDate.slice(8, 10) === "31") {
    const today = new Date();
    return formatDate(today);
  }
  return endDate;
};

export const generatePDF = (report, startDate, endDate) => {
  getTodayDate(endDate);
  // initialize jsPDF
  const doc = new jsPDF();

  jsPDF.autoTableSetDefaults({
    theme: "striped",
    tableWidth: "auto",
    columnWidth: "auto",
    showHead: "everyPage",
    rowPageBreak: "avoid",
    pageBreak: "auto",
    didDrawPage: function (data) {
      //header
      doc.addImage(Logo, "PNG", 115, 5, 90, 7);

      //footer
      let str = `${doc.internal.getNumberOfPages()}`;
      doc.setFontSize(10);
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      doc.text(str, pageWidth / 2, pageHeight - 2);
    },
    margin: { top: 30, bottom: 20 },
    styles: {
      overflow: "linebreak",
    },
    columnStyles: { 1: { halign: "center", cellWidth: 20 } },
    headStyles: { fillColor: "#223e7f" },
  });

  const width = doc.internal.pageSize.getWidth();
  doc.text(
    `Financial report from ${startDate} to ${getTodayDate(endDate)}`,
    width / 2,
    25,
    {
      align: "center",
    }
  );

  if (report.summary.length > 0) {
    const tableColumnSummary = ["Type", "Amount"];
    const summaryRows = [];

    report.summary.forEach((item) => {
      const summaryData = [item.type, `$${item.amount}`];
      summaryRows.push(summaryData);
    });

    doc.setFontSize(12);
    doc.text("Summary report", 15, 40);
    doc.autoTable({
      columns: tableColumnSummary,
      body: summaryRows,
      startY: 45,
    });
  }

  if (report.commission.length > 0) {
    const tableColumnComission = ["Name", "Amount"];
    const commissionRows = [];

    report.commission.forEach((item) => {
      const commissionData = [item.name, `$${item.amount}`];
      commissionRows.push(commissionData);
    });

    let finalY = doc.previousAutoTable.finalY;
    if (!finalY) {
      finalY = 30;
    }

    doc.setFontSize(12);
    doc.text("Commission report", 15, finalY + 10);
    doc.autoTable({
      columns: tableColumnComission,
      body: commissionRows,
      startY: finalY + 15,
    });
  }

  const date = Date().split(" ");
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

  // we define the name of our PDF file.
  doc.save(`report_${dateStr}.pdf`);
};
