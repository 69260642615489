import { SectionWrapper } from "../section-wrapper/sectionWrapper.styles";
import { Link } from "react-router-dom";

import "./dashboard-card.scss";

const DashbaordCard = ({ title, link, path, content, subtitle }) => {
  return (
    <SectionWrapper fullWidth={false} card={true}>
      <h2 className="dashbaord__card__title">{title}</h2>
      <div className="dashbaord__card__content">
        <h2 className="dashbaord__card__main">{content}</h2>
        <h2 className="dashbaord__card__subtitle">{subtitle}</h2>
      </div>
      <Link to={path}>{link}</Link>
    </SectionWrapper>
  );
};

export default DashbaordCard;
