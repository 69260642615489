import Pagination from "../pagination/pagination.component";
import { useState } from "react";

const CommissionsTable = ({ commissions, title }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  //Get current posts
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = commissions.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => {
    if (pageNumber === 0) {
      setCurrentPage(() => currentPage - 1);
    } else if (pageNumber > commissions.length) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="responsive__table__container">
      <h2 className="header__title">{title}</h2>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Company</th>
            <th scope="col" className="text-right">
              Amount
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0
            ? currentItems.map((item) => {
                return (
                  <tr key={item.name + item.amount}>
                    <th scope="row" key={item.name}>
                      {item.name}
                    </th>
                    <td key={item.amount} className="text-right">
                      ${item.amount}
                    </td>
                    <td></td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {commissions.length > itemsPerPage ? (
        <nav aria-label="Commisions navigation">
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={commissions.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </nav>
      ) : null}
    </div>
  );
};

export default CommissionsTable;
