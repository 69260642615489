import { useState } from "react";

import Pagination from "../pagination/pagination.component";
import { SectionWrapper } from "../section-wrapper/sectionWrapper.styles";

const AdvisorsSection = ({ advisors }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  //Get current posts
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = advisors.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => {
    if (pageNumber === 0) {
      setCurrentPage(() => currentPage - 1);
    } else if (pageNumber > advisors.length) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <SectionWrapper title={"Advisors"}>
      <div className="responsive__table__container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Advisors ID</th>
              <th scope="col">Last Name</th>
              <th scope="col">First Name</th>
              <th scope="col">Email</th>
              <th scope="col">Relationship</th>
              <th scope="col">Description</th>
            </tr>
          </thead>
          <tbody>
            {advisors.length
              ? currentItems.map((item) => {
                  return item.advisorId === null ? null : (
                    <tr key={item.id}>
                      <th key={item.advisorId} scope="row">
                        {item.advisorId}
                      </th>
                      <td key={item.lastName}>{item.lastName}</td>
                      <td key={item.firstName}>{item.firstName}</td>
                      <td key={item.email}>{item.email}</td>
                      <td key={item.relationship}>{item.relationship}</td>
                      <td key={item.description}>{item.description}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      {advisors.length > itemsPerPage ? (
        <nav aria-label="Advisors navigation">
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={advisors.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </nav>
      ) : null}
    </SectionWrapper>
  );
};
export default AdvisorsSection;
