import { SectionWrapper } from "../../section-wrapper/sectionWrapper.styles";
import InfoItem from "../info-item/info-item.component";

import "./account-info.styles.scss";

const AccountInfoAdvisor = ({ info }) => (
  <SectionWrapper title={"Account Information"}>
    <div className="advisor__info__container">
      <InfoItem
        label="Name: "
        value={`${info.firstName} ${info.lastName}`}
      ></InfoItem>
      <InfoItem label="Advisor Id: " value={info.advisorId}></InfoItem>
      <InfoItem label="Email: " value={info.email}></InfoItem>
      <InfoItem label="Relationship: " value={info.relationship}></InfoItem>
      <InfoItem label="Description: " value={info.description}></InfoItem>
    </div>
  </SectionWrapper>
);

export default AccountInfoAdvisor;
