import PageWrapper from "../../components/page-wrapper/page-wrapper.components";
import DistributionSection from "../../components/distribution/distribution.component";

const DistributionPage = () => {
  return (
    <PageWrapper>
      <DistributionSection />
    </PageWrapper>
  );
};

export default DistributionPage;
