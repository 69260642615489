import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectCurrentUser } from "../../redux/user/user.selectors";
import { selectCurrentDistro } from "../../redux/distro/distro.selector";

import PageWrapper from "../../components/page-wrapper/page-wrapper.components";
import { Spinner } from "../../components/spinner/spinner.component";
import { SectionWrapper } from "../../components/section-wrapper/sectionWrapper.styles";
import CommissionsTable from "../../components/commissions/commisions.component";

import { formatDate } from "../../components/financials/utils";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const ClientsSection = ({ currentUser, currentDistro }) => {
  const [financialInfo, setFinancialInfo] = useState({
    commission: [],
    summary: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_URL_DEV
          : process.env.REACT_APP_API_URL_LOCAL;
  
  const fetchData = async (startingDate, endingDate) => {
    try {
      setIsLoading(true);
      const accessToken = currentUser.token;
      const response = await fetch(
        `${apiUrl}/financials/${currentDistro}/${formatDate(
          startingDate
        )}/${formatDate(endingDate)}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setIsLoading(false);
        if (isLoading === false) {
          setFinancialInfo(data);
        }
      } else {
        setIsLoading(false);
        throw new Error(response.statusText);
      }
    } catch (err) {
      localStorage.clear();
      alert(
        "An error has occured while retrieving data. Please refresh the page and try again."
      );
    }
  };

  useEffect(() => {
    let defaultEndDate = new Date();
    defaultEndDate.setDate(1);
    defaultEndDate.setHours(-1);
    let defaultStarDate = new Date(defaultEndDate.getFullYear(), defaultEndDate.getMonth(), 1);
    if (currentDistro !== null) {
      fetchData(defaultStarDate, defaultEndDate);
    }
    return (isLoading) => (isLoading = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDistro]);

  return (
    <PageWrapper>
      <SectionWrapper title={"Clients"}>
        <div className="section__container">
          <div className="tables__container">
            {financialInfo.commission.length > 0 ? (
              <CommissionsTable commissions={financialInfo.commission} />
            ) : null}

            {isLoading ? (
              <div className="financial__spinner">
                <Spinner className="spin" style={{ display: "none" }} />
              </div>
            ) : null}
          </div>
        </div>
      </SectionWrapper>
    </PageWrapper>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentDistro: selectCurrentDistro,
});

export default connect(mapStateToProps)(ClientsSection);
