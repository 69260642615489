import Header from "../../components/headerBlue/header.component";
import { SectionWrapper } from "../../components/section-wrapper/sectionWrapper.styles";

import { Link } from "react-router-dom";

const PageNotFound = () => (
  <>
    <Header />
    <div className="page__container bg-white">
      <SectionWrapper>
        <h1 className="not__found__title text-center">404</h1>
        <h2 className="text-center">
          The page you requested could not be found.
        </h2>
        <h4 className="text-center">
          Go back <Link to="/">home</Link>
        </h4>
      </SectionWrapper>
    </div>
  </>
);

export default PageNotFound;
