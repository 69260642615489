import invertedLogo from "../../assets/NHC Logo inverted RGB.jpg";
import menuBars from "../../assets/menuBars.svg";
import menuClose from "../../assets/menuClose.svg";
import {useLocation} from 'react-router-dom';
import {FaSave} from 'react-icons/fa';
import { FaPaperPlane } from 'react-icons/fa';
import {Spinner} from 'react-bootstrap';

import "./header.styles.scss";

const Header = ({onSave, onSend, mode, onClick, showSidebar, saving, sending}) => {
    const location = useLocation();
    return (
        <div className="header__container" style={{display: 'flex', justifyContent: 'space-between'}}>
            {showSidebar ? (
                <img
                    className="menu__logo"
                    src={menuClose}
                    alt="Logo"
                    onClick={onClick}
                />
            ) : (
                <img
                    className="menu__logo"
                    src={menuBars}
                    alt="Logo"
                    onClick={onClick}
                />
            )}

            <div className="logo__container">
                <img className="header__logo" src={invertedLogo} alt="Logo"/>
                {!location.pathname.startsWith('/enroll') && <h4 className="header-title">ADVISOR PORTAL</h4>}
            </div>

            {mode === 'edit' && (
                <div className="text-end m-4 pb-2">
                    <button className="btn btn-warning" onClick={onSave} style={{minWidth: 140,marginRight:15}}>

                        {saving ? <span>Saving <Spinner animation="border" size={"sm"} variant="light"
                                                        className={"ms-2"}/></span> :
                            <span>Save <FaSave className={"ms-2"}/></span>}
                    </button>
                    <button className="btn btn-success" onClick={onSend} style={{minWidth: 140}}>

                        {sending ? <span>Sending <Spinner animation="border" size={"sm"} variant="light"
                                                          className={"ms-2"}/></span> :
                            <span>Send <FaPaperPlane className={"ms-2"}/></span>}
                    </button>
                </div>
            )}

        </div>
    );
};

export default Header;
