import "./info-item.styles.scss";

const InfoItem = ({ label, value }) => (
  <div className="info__item__container">
    <span className="info__item__label">{label}</span>
    <span className="info__item__value">{value}</span>
  </div>
);

export default InfoItem;
