export const ownerSidebarData = [
  {
    section: "Reports",
    links: [
      {
        id: 1,
        externalLink: false,
        title: "Dashboard",
        path: "/user/dashboard",
      },
      {
        id: 2,
        externalLink: false,
        title: "Financials",
        path: "/user/financials",
      },
      {
        id: 3,
        externalLink: false,
        title: "Commissions",
        path: "/user/commission",
      },
      {
        id: 4,
        externalLink: false,
        title: "Clients",
        path: "/user/clients",
      },
    ],
  },
  {
    section: "Tools",
    links: [
      {
        id: 5,
        externalLink: true,
        title: "Build a quote",
        path: "https://proposal1.healthclaim.ca/",
      },
    ],
  },
  {
    section: "Marketing",
    links: [
      {
        id: 6,
        externalLink: false,
        title: "Enrollments",
        path: "/user/enrollments",
      },
      // {
      //   id: 6,
      //   externalLink: true,
      //   title: "Enrollments",
      //   path: "https://advisor.nationalhealthclaim.com/enrollment.html",
      // },
      {
        id: 7,
        externalLink: true,
        title: "PDFs",
        path: "https://advisor.nationalhealthclaim.com/marketing.html",
      },
      {
        id: 8,
        externalLink: true,
        title: "Shareable Videos",
        path: "https://advisor.nationalhealthclaim.com/marketing.html",
      },
      {
        id: 9,
        externalLink: true,
        title: "Articles",
        path: "https://advisor.nationalhealthclaim.com/ideas.html?id=b1fc9da9-9948-408a-b007-0573f0801f92",
      },
    ],
  },
  // {
  //   section: "Courses",
  //   links: [
  //     {
  //       id: 10,
  //       externalLink: true,
  //       title: "HSA 101",
  //       path: "https://proposal1.healthclaim.ca/",
  //     },
  //   ],
  // },
  {
    section: "Account",
    links: [
      {
        id: 11,
        externalLink: false,
        title: "Profile",
        path: "/user/profile",
      },
      {
        id: 12,
        externalLink: false,
        title: "Advisors",
        path: "/user/advisors",
      },
    ],
  },
];

export const advisorSidebarData = [
  {
    section: "Account",
    links: [
      {
        id: 1,
        externalLink: false,
        title: "Profile",
        path: "/user/profile",
      },
      {
        id: 2,
        externalLink: false,
        title: "Companies",
        path: "/user/companies",
      },
    ],
  },
];
